import React from "react";
import Tilt from "react-tilt";

export default function About({ skills, moreInfo }) {
	const skillsLength = skills.length;
	// console.log(skillsLength);

	const skillLeft = skills.slice(0, skillsLength / 2).map((skill, i) => {
		return <li key={i}>{skill.name}</li>;
	});
	const skillRight = skills
		.slice(skillsLength / 2, skillsLength)
		.map((skill, i) => {
			return <li key={i}>{skill.name}</li>;
		});
	return (
		<div className='about-wrapper'>
			<div className='about-me'>
				<h3>{"01. More about me"}</h3>
				<div className='line'></div>
				<p>{moreInfo}</p>
				<hr></hr>
				<h4>{"Top Expertise"}</h4>
				<div className='line'></div>
				<p>
					{"Languages and Technologies I am most comfortable with, "}
					<a
						href='https://ashokcpg.com.np/resume'
						target='_blank'
						rel='noopener noreferrer'>
						Request for Resume
					</a>
				</p>
				<Tilt className='Tilt' options={{ max: 35, scale: 1.001 }}>
					<div className='Tilt-inner'>
						<div id='skills'>
							<ul>{skillLeft}</ul>
							<ul>{skillRight}</ul>
						</div>
					</div>
				</Tilt>
			</div>
		</div>
	);
}

import React, { Component } from "react";
import Placeholder from "../static/placeholder.jpg";

export default class Projects extends Component {
	constructor(props) {
		super(props);
		this.state = {
			projects: this.props.projects,
			isLoaded: false,
		};
	}

	componentDidMount() {
		// console.log(this.state.projects);
	}

	// console.log(this.state);

	render() {
		console.log(Placeholder);
		const blogItems = this.state.projects.map((Item, i) => (
			<div className='post' key={i}>
				<div className='img-container'>
					<img
						className='thumbnail'
						src={
							this.state.projects[i].images[0]
								? this.state.projects[i].images[0].resolutions.thumbnail.url
								: Placeholder
						}
						alt=''
					/>
					<div className='languages-container'>
						{Item.languages.map((language, i) => (
							<p key={i}>
								<span>{language}</span>
							</p>
						))}
					</div>
				</div>
				<div className='post-preview'>
					<h6 className='post-title'>{Item.displayName}</h6>
					<p className='post-intro'>{Item.summary}</p>
				</div>
				<div className='project-links-container'>
					<ul>
						{Item.githubUrl ? (
							<li>
								<a href={Item.githubUrl}>Repo</a>
							</li>
						) : null}
						{Item.website ? (
							<li>
								<a
									href={Item.website}
									target='_blank'
									rel='noopener noreferrer'>
									Demo
								</a>
							</li>
						) : null}
					</ul>
				</div>
			</div>
		));
		return <div className='post-wrapper'>{blogItems} </div>;
	}
}

import React, { Component } from "react";
import swal from "sweetalert";

export default class ContactUs extends Component {
	sendEmail(e) {
		e.preventDefault();
		let btn = document.getElementById("submit-btn");
		btn.value = "Sending Your Message...";

		const btnChanger = () => {
			if (btn.value === "Sending Your Message...") {
				btn.value = "Send ➡";
				return false;
			}
		};
		swal({
			title: "Oops, This is an old website!!!",
			text: "Please visit https://ashokcpg.com.np to contact me!!!",
			icon: "error",
			button: "Got It!",
		});
		btnChanger();
		e.target.reset();
	}


	render() {
		return (
			<form id='contact-form' method='post' onSubmit={this.sendEmail}>
				<div className='contact-wrapper'>
					<div className='name'>
						<label>Name</label>
						<input
							type='text'
							name='name'
							className='input-field'
							required
							placeholder='John Doe'
						/>
					</div>
					<div className='email'>
						<label>Email</label>
						<input
							type='email'
							name='email'
							className='input-field'
							required
							placeholder='example@gmail.com'
						/>
					</div>
					<div className='message'>
						<label>Message</label>
						<textarea
							name='message'
							className='input-field'
							required
							placeholder='Hi,'></textarea>
					</div>
				</div>
				<input type='submit' value='Send ➡' id='submit-btn' />
			</form>
		);
	}
}

import React, { Component } from "react";
import {
	SiHackerrank,
	SiTopcoder,
	SiQuora,
	SiBehance,
	SiTwitch,
	SiVimeo,
} from "react-icons/si";

import {
	FiFacebook,
	FiLinkedin,
	FiGithub,
	FiTwitter,
	FiYoutube,
} from "react-icons/fi";
import { FaMediumM, FaStackOverflow, FaDribbble } from "react-icons/fa";
import { AiOutlineReddit } from "react-icons/ai";

export default class SocialLinks extends Component {
	render() {
		let Links = this.props.links.shift();
		Links = this.props.links.map((link, i) => {
			switch (link.network) {
				case "GitHub":
					// console.log("Inside Github");
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FiGithub />
						</a>
					);
				case "LinkedIn":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FiLinkedin />
						</a>
					);
				case "Twitter":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FiTwitter />
						</a>
					);
				case "Stack Overflow":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FaStackOverflow />
						</a>
					);
				case "Youtube":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FiYoutube />
						</a>
					);
				case "Medium":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FaMediumM />
						</a>
					);
				case "SiReddit":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<AiOutlineReddit />
						</a>
					);
				case "HackerRank":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiHackerrank />
						</a>
					);
				case "Topcoder":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiTopcoder />
						</a>
					);
				case "Facebook":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FiFacebook />
						</a>
					);
				case "Quora":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiQuora />
						</a>
					);
				case "Behance":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiBehance />
						</a>
					);
				case "Dribbble":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<FaDribbble />
						</a>
					);
				case "Twitch":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiTwitch />
						</a>
					);
				case "Vimeo":
					return (
						<a
							href={link.url}
							key={i}
							className={link.network}
							target='_blank'
							rel='noreferrer'>
							<SiVimeo />
						</a>
					);
				default:
					return null;
			}
		});
		return <div className='social-links__container'>{Links}</div>;
	}
}

import React, { Component } from "react";
import "./sass/main.scss";

// Importing UI Components
import Loading from "./component/Loading.js";
import ProfileHead from "./component/Profile";
import IntroComp from "./component/IntroComp";
import About from "./component/About";
import Projects from "./component/Projects";
import ContactUs from "./component/Contact";
import Footer from "./component/Footer";
// Imported info.json incase of faliure to fetch data.

// Importing Axios...
const axios = require("axios");
export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
		};
	}
	// Getting Json Data

	componentDidMount() {
		// Your GitConnected URL Fetched !
		const gitConnectedUsername = process.env.REACT_APP_gitConnected_Username;
		let dataFromSessionStorage = JSON.parse(sessionStorage.getItem("info"));
		if (navigator.onLine && dataFromSessionStorage === null) {
			// console.log("Data Fetched from Gitconnected...");
			axios
				.get("https://gitconnected.com/v1/portfolio/" + gitConnectedUsername)
				.then((response) => {
					//Fetches Data from Local Storage if Failed...
					return response.status === 200
						? response.data
						: dataFromSessionStorage;
				})
				.then((myData) => {
					sessionStorage.setItem("info", JSON.stringify(myData));
					this.setState({ data: myData });
				});
		} else {
			// console.log("Data Fetched from LocalStorage...");
			return this.setState({ data: dataFromSessionStorage });
		}
	}

	render() {
		if (this.state.data === null) {
			// console.log("LOADING...");
			return (
				<div>
					<Loading />
				</div>
			);
		} else {
			// console.log("DONE...");

			const myinfo = this.state.data;
			// console.log("Main Component");
			return (
				<div className='wrapper'>
					<section className='s1'>
						<div className='main-container'>
							<ProfileHead name={myinfo.basics.name} />
							<IntroComp
								photo={myinfo.basics.picture}
								summary={myinfo.basics.headline}
								socialLinks={myinfo.basics.profiles}
							/>
						</div>
					</section>
					{/* Section 2 */}
					<section className='s2'>
						<About skills={myinfo.skills} moreInfo={myinfo.basics.summary} />
					</section>
					<section className='s1' id='projects'>
						<div className='main-container'>
							<h3 className='center'>03. Past Projects</h3>
							<Projects projects={myinfo.projects} />
						</div>
					</section>
					<section className='s2' id='contact'>
						<div className='main-container'>
							<h3 className='center'>{"04. Get in Touch"}</h3>
							<ContactUs />
						</div>
					</section>
					<section className='s1 footer'>
						<div className='main-container'>
							<Footer />
						</div>
					</section>
				</div>
			);
		}
	}
}

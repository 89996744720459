import React from "react";

export default function Footer(props) {
	return (
		<div className='footer-text'>
			<h5>
				Created with{" "}
				<span role='img' aria-labelledby='Heart Emoji'>
					💖
				</span>{" "}
				by Ashok Chapagai.
			</h5>
		</div>
	);
}

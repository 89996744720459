import React from "react";
import Tilt from "react-tilt";

export default function LeftSide(props) {
	return (
		<div>
			<div className='left-column'>
				<div className='img-container'>
					<div className='profile-container'>
						<Tilt className='Tilt' options={{ max: 35 }}>
							<div className='Tilt-inner'>
								<img
									src={props.photo}
									id='profile_pic'
									alt='Profile pic here!'
								/>
							</div>
						</Tilt>
					</div>
				</div>
				<h5 className='center text-heading'>
					Change Color Scheme ( To be implemented )
				</h5>
				<div className='theme-options-wrapper'>
					<div className='theme-dot' id='light-mode' data-mode='default'></div>
					<div className='theme-dot' id='dark-mode' data-mode='dark'></div>
					<div className='theme-dot' id='green-mode' data-mode='green'></div>
					<div className='theme-dot' id='purple-mode' data-mode='purple'></div>
					<div className='theme-dot' id='red-mode' data-mode='red'></div>
				</div>
				<p className='settings-note'>
					*Theme is set as per Local Time. (Light/Dark)
				</p>
				<p className='settings-note'>
					*If changed, settings will be saved on Local Storage.
				</p>
			</div>
		</div>
	);
}

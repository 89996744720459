import React, { Component } from "react";
import "react-awesome-slider/dist/styles.css";
import BlogPostShower from "./BlogPostShower";
const axios = require("axios");
// var Parser = require("rss-parser");
// var parser = new Parser();

// Set your Medium Username here!!!
const MEDIUM_USERNAME = process.env.REACT_APP_mediumUsername;
const rss2json = process.env.REACT_APP_rss2json_API;
export default class AboutRight extends Component {
	constructor(props) {
		super(props);
		this.state = {
			blogItems: [],
			isLoaded: false,
			blogPosts: "",
		};
	}

	// Fetching Medium Blogs
	async componentDidMount() {
		try {
			const res = await axios.get(
				"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@" +
					MEDIUM_USERNAME +
					"&api_key=" +
					rss2json
			);
			this.setState({
				blogItems: res.data.items,
				isLoaded: true,
			});
		} catch (err) {
			console.log("Error in fetching data...");
		}

		// await fetch(
		// 	"https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@" +
		// 		MEDIUM_USERNAME +
		// 		"&api_key=" +
		// 		rss2json
		// )
		// 	.then((response) => response.json())
		// 	.then((data) => {
		// 		// console.log(data);
		// 		this.setState({
		// 			blogItems: data.items,
		// 			isLoaded: true,
		// 		});
		// 	});

		// parser.parseURL(
		// 	"https://medium.com/feed/@" + MEDIUM_USERNAME,
		// 	(err, feed) => {
		// 		console.log(feed);
		// 		this.setState({
		// 			blogItems: feed.items,
		// 			isLoaded: true,
		// 		});
		// 	}
		// );
	}

	render() {
		if (!this.state.isLoaded) {
			// console.log("Content Not Loaded Yet");
			return <h3 className='center-abs'>No BlogPosts to Show</h3>;
		} else {
			// console.log("Content Finally Loaded...");
			// console.log(this.state.blogItems);
			return (
				<div className='blog-social-wrapper'>
					<h3>02. I write blogs as well.</h3>
					<div className='line'></div>
					<BlogPostShower blogItems={this.state.blogItems} />
				</div>
			);
		}
	}
}

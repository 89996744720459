import NavBar from "../subcomponent/NavBar";
import LeftSide from "../subcomponent/LeftSide";
import RightSide from "../subcomponent/RightSide";
import React, { Component } from "react";

export default class IntroComp extends Component {
	render() {
		// console.log("Intro Component");
		const { photo, summary, socialLinks } = this.props;
		return (
			<div>
				<div className='intro-wrapper'>
					<NavBar />
					<LeftSide photo={photo} />
					<RightSide summary={summary} socialLinks={socialLinks} />
				</div>
			</div>
		);
	}
}

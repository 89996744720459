import React from "react";
import Typical from "react-typical";
export default function ProfileHead({ name }) {
	return (
		<div className='greeting-wrapper'>
			<h1 className='heading'>Hi, I am &nbsp;</h1>
			<Typical
				steps={[
					name + ". 👦",
					4000,
					"a Friend. ✌",
					2000,
					"a Developer. 💻",
					2000,
					"a Learner.💡",
					2000,
					"a Designer. 🎨",
					2000,
					"a Security Researcher.🐱‍💻",
					2000,
				]}
				loop={Infinity}
				wrapper='h1'
			/>
		</div>
	);
}

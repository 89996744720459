import React from "react";

export default function NavBar() {
	return (
		<div className='nav-wrapper'>
			<div className='dots-wrapper'>
				<div id='dot-1' className='browser-dot'></div>
				<div id='dot-2' className='browser-dot'></div>
				<div id='dot-3' className='browser-dot'></div>
			</div>
			<ul id='navigation'>
				<li>
					<a href='#contact'>Contact</a>
				</li>
				<li>
					<a href='#projects'>Projects</a>
				</li>
			</ul>
		</div>
	);
}

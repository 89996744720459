import React, { Component } from "react";
import SocialLinks from "./SocialLinks";
import Tilt from "react-tilt";

export default class RightSide extends Component {
	render() {
		// console.log("Right Side");
		const { summary, socialLinks } = this.props;
		return (
			<div>
				<div className='right-column'>
					<Tilt className='Tilt' options={{ max: 35 }}>
						<div className='Tilt-inner'>
							<div className='preview-shadow'>
								<div className='preview'>
									<div className='corner'></div>
									<div className='corner'></div>
									<div className='corner'></div>
									<div className='corner'></div>
									<h3>{"00. Summary"}</h3>
									<div className='line'></div>
									<p>{summary}</p>
								</div>
							</div>
						</div>
					</Tilt>
					<div className='social-links'>
						<h2 className='center'>{"Let's Get Connected..."}</h2>
						<SocialLinks links={socialLinks} />
					</div>
				</div>
			</div>
		);
	}
}

import React from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function BlogPostShower({ blogItems }) {
	// FOrmating date
	const formatDate = (date, something) => {
		switch (something) {
			case "day":
				return new Date(date).toString().split(/[ ]+/).slice(2, 3);
			case "month":
				return new Date(date).toString().split(/[ ]+/).slice(1, 2);
			default:
				return new Date(date).toString().split(/[ ]+/).slice(3, 4);
		}
	};

	const blogPosts = blogItems.map((Item, i) => (
		<div key={i}>
			<Slide index={i}>
				<div>
					<div className='blog-card card'>
						<div
							className='wrapper'
							style={{ backgroundImage: `url(${Item.thumbnail})` }}>
							<div className='date'>
								<span className='day'>{formatDate(Item.pubDate, "day")}</span>
								<span className='month'>
									{formatDate(Item.pubDate, "month")}
								</span>
								<span className='year'>{formatDate(Item.pubDate)}</span>
							</div>
							<div className='data'>
								<div className='content'>
									<h2 className='blog-title'>
										<a
											href={Item.link}
											target='_blank'
											rel='noopener noreferrer'>
											{Item.title}
										</a>
									</h2>
									<p className='text'>
										{Item.content
											.replace(/<\/?[^>]+(>|$)/g, "")

											.substr(0, 400) + "..."}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				;
			</Slide>
		</div>
	));
	return (
		<CarouselProvider
			naturalSlideWidth={100}
			naturalSlideHeight={60}
			totalSlides={blogItems.length}
			isPlaying={1}
			interval={2000}
			className={"slide"}>
			<Slider>{blogPosts}</Slider>
		</CarouselProvider>
	);
}

import React from "react";
import AboutLeft from "../subcomponent/AboutLeft";
import AboutRight from "../subcomponent/AboutRight";

export default function About({ skills, moreInfo }) {
	return (
		<div className='about-wrapper-main'>
			<AboutLeft skills={skills} moreInfo={moreInfo} />
			<AboutRight />
		</div>
	);
}
